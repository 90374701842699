import React from "react";

function Hero() {
  return (
    <section className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-7xl font-bold text-center leading-[89px] text-zinc-800 w-[890px] max-md:mt-1 max-md:max-w-full max-md:text-4xl max-md:leading-[52px]">
        Elevating Standards, Redefining Assurance
      </h1>
      <button className="px-12 py-4 mt-20 text-xl font-bold text-center text-white rounded-xl bg-zinc-800 w-[211px] max-md:px-5 max-md:mt-10">
        Get in Touch
      </button>
    </section>
  );
}

export default Hero;
