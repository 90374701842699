import React, { useState } from "react";
import { Box, Typography, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledBox = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFF",
  display: "flex",
  flexDirection: "column",
  padding: "80px",
  [theme.breakpoints.down("md")]: {
    padding: "0 20px",
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  aspectRatio: "1",
  objectFit: "auto",
  objectPosition: "center",
  width: "64px",
  marginTop: "100px",
  [theme.breakpoints.down("md")]: {
    marginTop: "40px",
  },
}));

const StyledPasswordField = styled(TextField)(({ theme }) => ({
  width: "420px",
  maxWidth: "100%",
  marginTop: "4px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-input": {
    paddingLeft: theme.spacing(2.5),
  },
}));

const TextContainer = styled(Box)({
  width: "100%",
  maxWidth: "420px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const Reset = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
      // Proceed with password reset logic
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledBox>
      <StyledImage loading="lazy" src="lock.png" />

      <Typography
        sx={{
          color: "var(--Text-600-Headers, #1A181B)",
          textAlign: "center",
          marginTop: "40px",
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: "150%",
          fontFamily: "Inter, sans-serif",
        }}
      >
        Reset your password
      </Typography>
      <Typography
        sx={{
          color: "var(--Text-400-Paragraph, #4D4950)",
          textAlign: "center",
          marginTop: "16px",
          width: "420px",
          fontWeight: 400,
          fontSize: "15px",
          lineHeight: "24px",
          fontFamily: "Inter, sans-serif",
        }}
      >
        Include number letters or special characters for
        <br />
        stronger passwords
      </Typography>
      
      <TextContainer>
        <Typography
          sx={{
            color: "var(--Text-500-Component, #333036)",
            marginTop: "20px",
            textAlign: "left",
            fontWeight: 450,
            fontSize: "14px",
            lineHeight: "145%",
            fontFamily: "Inter, sans-serif",
          }}
        >
          New Password
        </Typography>
        <StyledPasswordField
          variant="outlined"
          type={showPassword ? "text" : "password"}
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          sx={{
            color: "var(--Text-500-Component, #333036)",
            marginTop: "20px",
            textAlign: "left",
            fontWeight: 450,
            fontSize: "14px",
            lineHeight: "145%",
            fontFamily: "Inter, sans-serif",
          }}
        >
          Confirm Password
        </Typography>
        <StyledPasswordField
          variant="outlined"
          type={showPassword ? "text" : "password"}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </TextContainer>
      
      {error && (
        <Typography
          sx={{
            color: "red",
            marginTop: "10px",
            fontWeight: 450,
            fontSize: "14px",
            lineHeight: "145%",
            fontFamily: "Inter, sans-serif",
          }}
        >
          {error}
        </Typography>
      )}
      <Button
        variant="contained"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
          backgroundColor: "var(--Link-400, #006EE6)",
          marginTop: "32px",
          width: "420px",
          maxWidth: "100%",
          color: "var(--Text-100-Always-White, #FFF)",
          padding: "12px 16px",
          fontWeight: 500,
          fontSize: "15px",
          lineHeight: "133%",
          fontFamily: "Inter, sans-serif",
        }}
        onClick={handleResetPassword}
      >
        Reset Password
      </Button>
    </StyledBox>
  );
};

export default Reset;
