import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header'; // Ensure the correct path to Header component
import Footer from './Footer'; // Ensure the correct path to Footer component

function ImageGallery() {
  const location = useLocation();
  const { company = {}, date = '', content = '', imageSrc = '', platform = '', isExpanded: initialExpanded = false } = location.state || {};

  const [isExpanded, setIsExpanded] = useState(initialExpanded);

  const handleClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const getTruncatedContent = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const MAX_CHARS = 100;
  const truncatedContent = getTruncatedContent(content, MAX_CHARS);

  return (
    <div className="flex flex-col bg-white min-h-screen">
      <Header />
      <div className="w-full h-[72px] px-80 py-4 bg-white border-b border-zinc-200 flex justify-start items-center gap-3">
        <div className="p-2.5 bg-neutral-50 rounded-lg flex items-start gap-2.5">
          <div className="w-5 h-5">
            {company.logo && (
              <img
                loading="lazy"
                src={company.logo}
                className="w-full aspect-square"
                alt="icon"
              />
            )}
          </div>
        </div>
        <div className="flex-grow flex justify-center items-center gap-2">
          <div>
            <span className="text-zinc-800 text-base font-medium capitalize leading-tight">
              Ad ID: 
            </span>
            <span className="text-blue-600 text-base font-medium capitalize leading-tight">
              374384829428473
            </span>
          </div>
        </div>
      </div>
      <div className="w-full max-md:px-5 max-md:max-w-full flex-grow">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow items-end pl-px-420 px-10 pt-10 pb-10 w-full bg-slate-50 max-md:px-5 max-md:mt-5 max-md:max-w-full">
              <div className="flex flex-col bg-white rounded-xl border border-solid border-zinc-200 w-full max-w-md ml-auto mr-5 max-md:mr-2.5">
                <div className="flex gap-3.5 items-start px-4 pt-3.5 pb-3 capitalize max-md:flex-wrap max-md:px-5">
                  {company.logo && (
                    <img
                      loading="lazy"
                      src={company.logo}
                      className="shrink-0 w-10 rounded-full aspect-square"
                      alt="user"
                    />
                  )}
                  <div className="flex flex-col flex-1 justify-center py-1 max-md:max-w-full">
                    <div className="text-base font-semibold leading-8 text-zinc-800 max-md:max-w-full">
                      {company.name || 'Unknown Company'}
                    </div>
                    <div className="mt-2 text-sm leading-3 text-neutral-500 max-md:max-w-full">
                      {date || 'Unknown Date'}
                    </div>
                  </div>
                </div>
                <div className="mx-4 mt-2 text-sm font-medium leading-5 text-neutral-500 max-md:mr-2.5 max-md:max-w-full">
                  {isExpanded ? content : truncatedContent}
                  {content && content.length > MAX_CHARS && (
                    <a
                      href="#!"
                      onClick={handleClick}
                      className="text-blue-500 ml-2"
                    >
                      {isExpanded ? 'See Less' : 'See More'}
                    </a>
                  )}
                </div>
                <img
                  loading="lazy"
                  src={imageSrc}
                  className="mt-4 w-full h-auto object-contain max-md:max-w-full"
                  alt="ad"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[410px] pr-[40px] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col mt-5 text-base font-medium rounded-xl border border-solid border-zinc-200 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-2 px-4 py-5 border-b border-solid border-zinc-200 leading-[120%] text-zinc-800 max-md:flex-wrap">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d457bc15845678d51327d09d913f3ec46191c76c6ee40a29dbd09bcb14e3f85?apiKey=1861f19fd7354dc091ef5609ab16de74&"
                  className="shrink-0 w-6 aspect-square"
                  alt="details icon"
                />
                <div className="my-auto max-md:max-w-full">Ad Details</div>
              </div>
              <div className="flex flex-col px-4 py-3 max-md:max-w-full">
                <div className="flex gap-3 py-2 leading-[120%] max-md:flex-wrap">
                  <div className="flex gap-2 whitespace-nowrap text-neutral-500">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/af21df3022e7d55a8462c9cfe1dfa750e49d29fd85ed1c87f734802c504e3766?apiKey=1861f19fd7354dc091ef5609ab16de74&"
                      className="shrink-0 w-6 rounded-full aspect-square"
                      alt="brand icon"
                    />
                    <div className="my-auto">Brand</div>
                  </div>
                  <div className="flex flex-1 gap-1.5 pr-4 my-auto text-blue-600">
                    <div>{company.name || 'Unknown Company'}</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/81eff07969435ee91ba09641b6abbab43090a330ce70d1137e022b7180b03a35?apiKey=1861f19fd7354dc091ef5609ab16de74&"
                      className="shrink-0 w-5 aspect-square"
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="flex gap-3 py-2 leading-[120%] max-md:flex-wrap">
                  <div className="flex gap-2 whitespace-nowrap text-neutral-500">
                    <div className="shrink-0 w-6 h-6 bg-green-600 rounded-full border-emerald-100 border-solid border-[5px] stroke-[5px]" />
                    <div className="my-auto">Status</div>
                  </div>
                  <div className="flex-1 my-auto text-zinc-800">
                    {date || 'Unknown Date'}
                  </div>
                </div>
                <div className="flex gap-3 py-2 leading-[120%] max-md:flex-wrap">
                  <div className="flex gap-2 text-neutral-500">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bf3dc73aacca2c47f7b05d0cf5c3b4a7257a57f3906d40613dd208998f391d74?apiKey=1861f19fd7354dc091ef5609ab16de74&"
                      className="shrink-0 w-6 aspect-square"
                      alt="time icon"
                    />
                    <div className="my-auto">Time Running</div>
                  </div>
                  <div className="flex-1 my-auto text-zinc-800">28 days</div>
                </div>
                <div className="flex gap-3 py-2 whitespace-nowrap leading-[120%] max-md:flex-wrap">
                  <div className="flex gap-2 text-neutral-500">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a69b19de802853a4c9c4c72ed5d8ac9f3fb2beb6d3108d4e6e1884e9362cd313?apiKey=1861f19fd7354dc091ef5609ab16de74&"
                      className="shrink-0 w-6 aspect-square"
                      alt="format icon"
                    />
                    <div className="my-auto">Format</div>
                  </div>
                  <div className="flex-1 my-auto text-zinc-800">Image</div>
                </div>
                <div className="flex gap-3 py-2 max-md:flex-wrap">
                  <div className="flex gap-2 self-start whitespace-nowrap leading-[120%] text-neutral-500">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/d37051cada92e967e8b82c5f192cb400223265054f210b64cd49bc7a30212320?apiKey=1861f19fd7354dc091ef5609ab16de74&"
                      className="shrink-0 w-6 aspect-square"
                      alt="platforms icon"
                    />
                    <div className="my-auto">Platforms</div>
                  </div>
                  <div className="flex-1 leading-7 text-zinc-800">
                    Facebook, Instagram, Audience Network, Messenger
                  </div>
                </div>
                <div className="flex gap-3 py-2 max-md:flex-wrap">
                  <div className="flex gap-2 self-start leading-[120%] text-neutral-500">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/19c7b2d1c1fea8277ffb6b14da523aa16588b739ece6823b37f86cff71757426?apiKey=1861f19fd7354dc091ef5609ab16de74&"
                      className="shrink-0 w-6 aspect-square"
                      alt="landing page icon"
                    />
                    <div className="my-auto">Landing Page</div>
                  </div>
                  <div className="flex-1 leading-[162.5%] text-zinc-800">
                    wholesale.southerngrace.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <Footer />
    </div>
  );
}

export default ImageGallery;
