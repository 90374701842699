import React, { useState } from 'react';
import CategoryItem from './CategoryItem';

const categories = [
  'VOIP', 'E-commerce', 'Food and Beverages', 'Jobs & Education',
  'Autos and Vehicles', 'Beauty and Fitness', 'Health'
];

function CategoryList({ onCategoryChange }) {
  return (
    <div className="flex flex-col bg-white rounded-xl items-stretch border shadow-sm border-zinc-200 mt-1 max-w-[320px] w-auto">
      {categories.map((category, index) => (
        <CategoryItem key={index} name={category} onCategoryChange={onCategoryChange} />
      ))}
    </div>
  );
}

export default CategoryList;
