import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import SectionTitle from "./SectionTitle";
import CardGrid from "./CardGrid";
import Clients from "./Clients";
import Testimonial from "./Testimonial";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

function MyComponent() {
  return (
    <div className="flex overflow-hidden flex-col pt-6 pb-20 bg-slate-50 max-md:pb-20">
      <Header />
      <Hero />
      
      <CardGrid />
      <Clients />
      <Testimonial />
      <Pricing />
      
      <FAQ />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default MyComponent;