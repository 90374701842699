import React from "react";

const faqsData = [
  { question: "How can I contact Fixiby Team?", answer: "You can reach us through our contact form on our website or by emailing us at hello@Fixiby.com. We typically respond within 24 hours." },
  { question: "What services do you offer?" },
  { question: "Do you provide website maintenance services?" },
  { question: "How long does it take to design and develop a website?" },
  { question: "Do you require a deposit for projects?" }
 ];

function FAQ() {
  return (
    <section className="flex justify-center mt-20 w-full max-w-[1147px] mx-auto max-md:mt-10 max-md:flex-col max-md:max-w-full">
      <div className="flex gap-5 w-full max-md:flex-col">
        <div className="flex flex-col justify-center w-[31%] max-md:w-full">
          <h2 className="text-6xl font-bold leading-[72px] text-zinc-800 text-justify max-md:text-4xl max-md:leading-[53px]">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="flex flex-col w-[69%] max-md:w-full">
          {faqsData.map((faq, index) => (
            <div key={index} className="flex flex-col mb-4">
              <div className="flex justify-between items-center gap-5 px-6 py-6 text-lg font-bold leading-tight rounded-xl border border-solid border-zinc-800 text-zinc-800 max-md:pl-5 max-md:max-w-full">
                <div>{faq.question}</div>
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e50dd3dab383284eb60e21d3208691d0bf04d364885f770db0b578c93ee6767?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Dropdown" className="object-contain shrink-0 w-6 aspect-square" />
              </div>
              {faq.answer && <div className="mt-4 text-base leading-6 text-zinc-800 max-md:max-w-full">{faq.answer}</div>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQ;
