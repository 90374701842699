import React, { useState } from 'react';
import CategoryList from './CategoryList';

export default function ToggleCategory({ setActiveConfig }) {
  const [activeButton, setActiveButton] = useState('Meta Ads');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMetaAdsClick = () => {
    setActiveButton('Meta Ads');
    setActiveConfig({ platform: 'Meta Ads', categories: selectedCategories });
  };

  const handleLinkedInAdsClick = () => {
    setActiveButton('LinkedIn Ads');
    setActiveConfig({ platform: 'LinkedIn Ads', categories: selectedCategories });
  };

  const handleCategoryClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCategoryChange = (category, isChecked) => {
    const updatedCategories = isChecked
      ? [...selectedCategories, category]
      : selectedCategories.filter(cat => cat !== category);
    
    setSelectedCategories(updatedCategories);
    setActiveConfig({ platform: activeButton, categories: updatedCategories });
  };

  return (
    <div className="flex items-center justify-between px-60 mt-16 max-w-full w-full max-md:mt-10 relative">
      <div className="flex gap-1 items-start self-stretch p-1 my-auto text-sm leading-none text-right rounded-xl bg-zinc-100 font-[450]">
        <button onClick={handleMetaAdsClick} className={`gap-1 self-stretch px-2.5 py-3.5 rounded-lg border-zinc-200 min-h-[36px] ${activeButton === 'Meta Ads' ? 'bg-white shadow-[0px_4px_4px_rgba(0,0,0,0.05)] text-zinc-800' : 'text-neutral-500'}`}>
          Meta Ads
        </button>
        <button onClick={handleLinkedInAdsClick} className={`gap-1 self-stretch px-2.5 py-3.5 rounded-lg border-zinc-200 min-h-[36px] ${activeButton === 'LinkedIn Ads' ? 'bg-white shadow-[0px_4px_4px_rgba(0,0,0,0.05)] text-zinc-800' : 'text-neutral-500'}`}>
          LinkedIn Ads
        </button>
      </div>
      <div className="flex items-center text-base leading-none font-[420] text-zinc-800">
        <div className="flex flex-col self-stretch my-auto rounded-xl">
          <button onClick={handleCategoryClick} className="flex overflow-hidden gap-2.5 items-center px-3.5 py-3 w-full bg-white rounded-xl border border-solid border-zinc-200">
            <img src="filter.png" alt="Filter icon" className="w-5 aspect-square" />
            <span className="flex-1">Category</span>
          </button>
          {showDropdown && (
            <div className="absolute z-10 top-full mt-1 bg-white border border-zinc-200 shadow-lg rounded-xl">
              <CategoryList onCategoryChange={handleCategoryChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
