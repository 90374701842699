// import React from 'react';

// const Footer = () => {
//   return (
//     <footer className="w-full py-4">
//       <div className="max-w-6xl mx-auto px-4 flex justify-between items-center">
//         <p className="text-gray-600">
//           &copy; 2024 Adstore. All Rights Reserved
//         </p>
//         <nav className="space-x-4">
//           <a href="#" className="hover:underline-animation text-gray-600">
//             Privacy Policy
//           </a>
//           <a href="#" className="hover:underline-animation text-gray-600">
//             Terms of Service
//           </a>
//         </nav>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
// 
import React from 'react';

const Footer = () => {
  return (
    <footer className="flex justify-center items-center self-stretch px-16 py-8 mt-5 w-full text-base leading-8 text-neutral-500 max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-wrap">
        <div className="flex-1 capitalize max-md:max-w-full">
          © 2024 Adstore. All rights reserved
        </div>
        <nav className="flex gap-5 justify-between">
          <a href="#privacy" className="capitalize">Privacy Policy</a>
          <a href="#terms">Terms of Service</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
