import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Home from './container/Home';
import Signup from './components/Signup';
import ImageGallery from './container/ImageGallery';
import ForgotPassword from './components/Forgotpassword';
import Reset from './components/Reset';
import Layout from './Dashboard/Layout';
import SideBar from './Dashboard/SideBar';
import { CategoryProvider } from './container/CategoryContext';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import MyComponent from './Fixiby/MyComponent';
const App = () => {
  return (
    <AuthProvider>
      <CategoryProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="MyComponent" element={<MyComponent />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="reset" element={<Reset />} />
          <Route path="imagegallery" element={<ImageGallery />} />
          <Route path="layout" element={<PrivateRoute element={<Layout />} />} />
          <Route path="sidebar" element={<PrivateRoute element={<SideBar />} />} />
        </Routes>
      </CategoryProvider>
    </AuthProvider>
  );
};

export default App;
