import React from 'react';

const Hero = () => {
  return (
    <section className="flex justify-center items-center px-16 mt-20 w-full text-center text-zinc-900 max-md:px-5 max-md:mt-10">
      <div className="flex flex-col items-center max-w-screen-lg w-full mx-auto px-5">
        <div className="justify-center px-4 py-2.5 text-sm leading-5 rounded-3xl border border-gray-300 border-solid">
          <span className="font-semibold text-sky-600">10000+</span>
          <span className="font-medium"> Ads and Counting</span>
        </div>
        <h1 className="self-stretch mt-10 text-6xl font-bold tracking-tighter capitalize bg-clip-text leading-[77px] max-md:max-w-full max-md:text-4xl max-md:leading-[53px]">
          Find and make winning ads 
        </h1>
        <h1 
          style={{
            background: 'linear-gradient(90deg, #006EE6 38.28%, #01BCD6 73.28%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontFamily: 'Inter',
            fontSize: '64px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '120%',
            letterSpacing: '-1.28px',
            textTransform: 'capitalize',
          }}
        >
          10x Faster
        </h1>
        <p className="mt-6 text-lg leading-8 max-w-[642px] w-full max-md:max-w-full">
          Research winning competitor ads and launch your own high-performing campaigns.
        </p>
      </div>
    </section>
  );
};

export default Hero;
