import React from "react";

function Testimonial() {
  return (
    <section className="flex flex-col self-center mt-12 ml-40 max-w-full text-sm font-medium leading-6 text-neutral-800 w-[253px] max-md:mt-10">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccf393012856c06d4db4c77f06e48af596aff37169cfe8e2ebe0933f4062f8a1?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Testimonial" className="object-contain aspect-[1.42] w-[17px]" />
      <div className="mt-5">Ipsum vel nobis doloremque est aut non accusantium vero molestias. Et est minima dolorem eum modi atque sint nobis. Enim quod facere. Reiciendis necessitatibus ipsam non aspernatur voluptate id.</div>
    </section>
  );
}

export default Testimonial;