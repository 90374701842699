import React from 'react';

const services = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/294a4646-9f58-4db3-ae87-67d2b459ec32?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
    title: "Holistic Quality Assurance",
    description: "We specialize in functional testing, performance analysis, & enhancing software reliability, helping you deliver a flawless product."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0378c95d-cd3f-427b-a18c-87ff80e65a39?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
    title: "Streamlined Automation Testing",
    description: "Software roll out with faster releases without compromising quality let our automation expertise drive efficiency & speed up your development process."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5f128e7d-cb3c-437a-85ae-769c4eeb27f5?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
    title: "Precision Manual Testing",
    description: "We focus on user experience, edge cases, and providing thorough test coverage, ensuring your application is flawless and user-friendly."
  },
  {
    icon: "FIX",
    title: "Accelerated CI/CD Pipelines",
    description: "From concept to launch, we create stunning, user-centric websites that elevate your brand and engage your audience."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a0622e5f-4fce-4b81-9821-f1a8e53b18a5?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
    title: "Fortified Security Testing",
    description: "With our comprehensive security testing services, we identify & mitigate potential threats before they become critical, ensuring your data and users remain safe."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/09f3b4df-3ca3-4ffb-8c83-95e0e4381112?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
    title: "QA Meets SEO",
    description: "Ensure the website functions perfectly, ranks higher on search engines. We focus on keyword optimization, site speed, & user engagement, drive more traffic conversions with quality content."
  }
];

function ServiceCard({ icon, title, description }) {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow items-start px-6 py-4 leading-5 rounded-xl border border-solid border-zinc-800 max-md:px-5 max-md:mt-8">
        {icon === "FIX" ? (
          <div className="px-3 pt-3.5 pb-5 w-11 h-11 font-bold text-black whitespace-nowrap rounded-lg bg-slate-50">
            FIX
          </div>
        ) : (
          <img loading="lazy" src={icon} alt="" className="object-contain w-11 h-11 rounded-lg bg-blend-normal aspect-square bg-slate-50" />
        )}
        <h3 className="mt-5 text-lg font-semibold text-zinc-800">
          {title.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== title.split('<br />').length - 1 && <br />}
            </React.Fragment>
          ))}
        </h3>
        <p className="self-stretch mt-2.5 text-sm text-zinc-800">
          {description}
        </p>
      </div>
    </div>
  );
}

function RobustPerformanceTesting() {
  return (
    <div className="flex flex-col ml-5 w-[24%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col px-7 py-5 mt-28 leading-5 rounded-xl border border-solid border-zinc-800 max-md:pr-5 max-md:mt-10">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a82f71c7-f810-421f-bcb0-0f823e8324d3?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="" className="object-contain w-11 h-11 rounded-lg aspect-square bg-slate-50" />
        <h3 className="mt-5 text-lg font-semibold text-zinc-800">
          Robust Performance <br /> Testing
        </h3>
        <p className="mt-2.5 text-sm text-zinc-800">
          We simulate real-world scenarios, identify bottlenecks, optimizing your application's performance under all conditions. Trust us to maintain your app's speed and reliability.
        </p>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0c0a845afcd139da5bcacc219b27c16428790a83342a6f8d337dcb2173d4ed44?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Performance testing visualization" className="object-contain mt-2 w-full rounded-md bg-blend-normal aspect-[0.93]" />
      </div>
    </div>
  );
}

function WhatWeDo() {
  return (
    <section className="rounded-none">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[76%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col w-full max-md:mt-8 max-md:max-w-full">
            <h2 className="self-start text-6xl font-bold leading-tight text-zinc-800 max-md:text-4xl">
              What We do
            </h2>
            <div className="mt-8 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col">
                {services.slice(0, 3).map((service, index) => (
                  <ServiceCard key={index} {...service} />
                ))}
              </div>
            </div>
            <div className="mt-14 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col">
                {services.slice(3).map((service, index) => (
                  <ServiceCard key={index + 3} {...service} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <RobustPerformanceTesting />
      </div>
    </section>
  );
}

export default WhatWeDo;