import React, { useState } from 'react';
import SchedulePost from './SchedulePost';

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <header className="flex flex-col px-8 w-full font-semibold max-md:px-5 max-md:max-w-full">
        <div className="flex flex-wrap gap-2.5 items-center py-5 w-full min-h-[80px] max-md:max-w-full">
          <h1 className="flex-1 shrink self-stretch my-auto text-2xl leading-none basis-7 text-zinc-900 max-md:max-w-full">
            Discover Ads
          </h1>
          <button
            onClick={handleOpenModal}
            className="flex gap-1.5 justify-center items-center self-stretch px-3.5 py-2.5 my-auto text-base leading-none text-white bg-blue-600 rounded-xl"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7decbc8a729cd161c70a5f0174918bf5357d753fe493d2626ceb9722cf568d67?apiKey=1861f19fd7354dc091ef5609ab16de74&&apiKey=1861f19fd7354dc091ef5609ab16de74"
              alt=""
              className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
            />
            <span className="self-stretch my-auto">Create New</span>
          </button>
        </div>
        <hr></hr>
      </header>
      {isModalOpen && <SchedulePost onClose={handleCloseModal} />}
    </div>
  );
}

export default Header;
