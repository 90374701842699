import React from "react";

function Header() {
  return (
    <header className="flex justify-center z-10 w-full max-md:max-w-full">
      <div className="flex flex-col w-full max-w-[1328px] px-6 max-md:max-w-full">
        <nav className="flex justify-between items-center w-full text-base">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4179d165cf3c4a2e9c0e4260c4c300a88db0baa854cd74f53a740d8a5c38c2b?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Company Logo" className="object-contain shrink-0 w-[101px]" />
          <div className="flex gap-10 items-center whitespace-nowrap text-zinc-400">
            <div className="font-bold text-zinc-800">Home</div>
            <div>About</div>
            <div>Resources</div>
            <div>Services</div>
            <div>Blogs</div>
          </div>
          <div className="flex gap-4 items-center">
            <div className="font-semibold text-zinc-700">Sign In</div>
            <div className="px-9 py-3 font-bold text-center text-white bg-indigo-600 rounded-xl max-md:px-5">Sign Up</div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
