import React from "react";

function Pricing() {
  return (
    <section className="flex flex-col items-center mt-20 w-full max-md:mt-10 max-md:max-w-full">
      <div className="w-full max-w-[1073px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow mt-7 font-bold max-md:mt-10">
              <div className="self-start text-6xl leading-tight text-zinc-800 max-md:text-4xl">Pricing</div>
              <div className="mt-4 mr-8 text-base leading-6 text-zinc-400 max-md:mr-2.5">
                You're investing in results, not in managing headcount, overhead, or dealing with attrition. We've created the most cost-effective approach to efficient test execution through a service model. With a single, low fixed monthly fee, you benefit from enhanced performance and a predictable cost structure that aligns with your budget.
              </div>
              <div className="flex flex-col px-7 py-8 mt-8 rounded-3xl border border-solid border-zinc-800 max-md:px-5">
                <div className="text-2xl leading-8 text-zinc-800 w-[243px]">Why Hire People Where You Can Hire a Company!</div>
                <div className="px-16 py-5 mt-5 text-base text-center text-white bg-indigo-600 rounded-xl max-md:px-5">Schedule Meeting</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[62%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col px-6 py-8 mx-auto w-full bg-white rounded-3xl max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <div className="self-start ml-7 text-6xl font-bold leading-tight text-indigo-600 max-md:ml-2.5 max-md:text-4xl">Standard</div>
              <div className="mt-2.5 ml-7 text-base leading-6 text-zinc-400 w-[383px] max-md:ml-2.5">Take a look at some of our recent projects to see how we've helped businesses like yours succeed online.</div>
              <div className="flex flex-wrap gap-10 self-center mt-9 max-w-full text-sm leading-6 text-zinc-800 w-[534px]">
                <div className="flex gap-3.5">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                  <div className="basis-auto">Eliminate Cost Uncertainty</div>
                </div>
                <div className="flex flex-auto gap-3.5">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                  <div className="basis-auto">Comprehensive Test Automation</div>
                </div>
              </div>
              <div className="flex flex-wrap gap-3.5 self-end mt-5 text-sm leading-6 text-zinc-800">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                <div className="basis-auto">Rapid Integration for Rapid Results</div>
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                <div className="basis-auto">Mitigate Hiring and Retention Risks</div>
              </div>
              <div className="self-center mt-5 max-w-full w-[496px]">
                <div className="flex gap-5 max-md:flex-col">
                  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col w-full text-sm leading-6 text-zinc-800 max-md:mt-10">
                      <div className="flex gap-3.5">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                        <div>Full Regression Testing at Every Build</div>
                      </div>
                      <div className="flex gap-3.5 mt-2 max-md:mr-2.5">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                        <div>Maximize Efficiency at a Fixed Cost</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col w-full text-sm leading-6 text-zinc-800 max-md:mt-10">
                      <div className="flex gap-3.5">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                        <div>Seamless CI/CD Toolchain Integration</div>
                      </div>
                      <div className="flex gap-3.5 self-start mt-2">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3a82affd622dcd8fe8b8d7a9aae0ecaaede66f2ddc30e7ca7676642fdd79263?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 self-start w-6 aspect-square" />
                        <div>Maximize Efficiency at a Fixed Cost</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-between px-11 pt-5 pb-9 mt-5 gap-5 whitespace-nowrap rounded-xl bg-blend-normal bg-slate-50 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-1">
                  <div className="grow text-6xl font-bold leading-tight text-zinc-800 max-md:text-4xl">$4,500</div>
                  <div className="self-end mt-9 text-xl text-zinc-400">/mo</div>
                </div>
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e7aa435cbeee2aac0dd393b0cbc588ae44b8c6c23c04e1f8a599037a4c42e061?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Checkmark" className="object-contain shrink-0 my-auto w-6 aspect-square" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;