import React from 'react';
import AdCard from './AdCard';

const AdGrid = ({ ads }) => {
  return (
    <div className="px-60 py-5 mt-10 w-full max-md:px-5">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        {ads.map((ad, index) => (
          <div key={index} className="flex flex-col">
            <AdCard {...ad} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdGrid;
