import React, { useState, useEffect } from 'react';
import SearchButtonFilter from './SearchButtonFilter';
import AdGallery from './AdGallery';
import axios from 'axios';

const MainContent = ({ activeTab, setActiveTab }) => {
  const [ads, setAds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [platform, setPlatform] = useState('Meta Ads');

  useEffect(() => {
    fetchAds();
  }, [categories, searchQuery, platform, activeTab]);

  const fetchAds = () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found');
      return;
    }

    const selectedCategories = categories.join(',');
    let url;

    if (activeTab === 'discover-ads') {
      url = `http://127.0.0.1:8000/api/v1/discover-ads/?categories=${selectedCategories}&search=${searchQuery}`;
    } else if (activeTab === 'saved-ads') {
      url = `http://127.0.0.1:8000/api/v1/ads/saved-ads/?categories=${selectedCategories}&search=${searchQuery}`;
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      const adsData = response.data.map(ad => ({
        id: ad.id,
        profileImage: ad.page.display_picture,
        name: ad.page.title,
        date: ad.ad_started_on,
        content: ad.caption,
        adImage: ad.ad_image_urls[0],
        platform: ad.platform,
      }));
      setAds(adsData);
    })
    .catch(error => {
      console.error('Error fetching ads:', error);
    });
  };

  const handleToggle = (selectedPlatform) => {
    setPlatform(selectedPlatform);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleCategoryChange = (selectedCategories) => {
    setCategories(selectedCategories);
  };

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <div className="main-content flex flex-col gap-4 p-4">
        <SearchButtonFilter 
          onSearch={handleSearch}
          onToggle={handleToggle}
          onCategoryChange={handleCategoryChange}
        />
        <AdGallery ads={ads} />
      </div>
    </div>
  );
};

export default MainContent;
