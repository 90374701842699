import React from "react";

function ContactForm() {
  return (
    <section className="flex flex-col items-center justify-center mx-auto mt-32 max-w-full text-lg font-bold text-zinc-800 w-[696px] max-md:mt-10">
      <h2 className="text-6xl leading-tight text-center max-md:max-w-full max-md:text-4xl">
        Let’s Get in Touch
      </h2>
      <form className="flex flex-col mt-16 w-full max-md:mt-10">
        <label htmlFor="emailInput" className="leading-tight max-md:ml-2">
          Email
        </label>
        <input
          className="px-8 py-5 mt-2.5 text-xl whitespace-nowrap rounded-xl border border-solid bg-blend-normal border-slate-200 text-zinc-400 max-md:px-5 max-md:max-w-full"
          type="email"
          id="emailInput"
          placeholder="example@email.com"
          aria-label="Enter your email"
        />
        <label htmlFor="nameInput" className="mt-4 leading-tight max-md:ml-2">
          Name
        </label>
        <input
          className="px-8 py-5 mt-2.5 text-xl rounded-xl border border-solid bg-blend-normal border-slate-200 text-zinc-400 max-md:px-5 max-md:max-w-full"
          type="text"
          id="nameInput"
          placeholder="Full name"
          aria-label="Enter your name"
        />
        <label htmlFor="messageInput" className="mt-4 leading-tight max-md:ml-2">
          Message
        </label>
        <textarea
          className="px-8 pt-5 pb-36 text-xl rounded-xl border border-solid bg-blend-normal border-slate-200 text-zinc-400 max-md:px-5 max-md:pb-28 max-md:max-w-full"
          id="messageInput"
          placeholder="Write your message..."
          aria-label="Enter your message"
        ></textarea>
        <button
          className="overflow-hidden px-16 py-5 mt-6 text-base font-bold text-center text-white bg-indigo-600 rounded-xl max-md:px-5 max-md:max-w-full"
          type="submit"
        >
          Get in Touch
        </button>
      </form>
    </section>
  );
}

export default ContactForm;
