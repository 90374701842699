import React from 'react';

const AdDetailsItem = ({ icon, title, label }) => (
  <div className="flex flex-wrap gap-4 items-center py-2 w-full rounded-[32px] max-md:max-w-full">
    {icon ? (
      <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" />
    ) : (
      <div className="flex shrink-0 self-stretch my-auto w-6 h-6 bg-green-600 rounded-full border-emerald-100 border-solid border-[5px]" />
    )}
    <div className="flex-1 shrink self-stretch my-auto basis-0 text-zinc-800 text-sm">  {/* Reduced font size */}
      {title}
    </div>
    <div className="self-stretch my-auto w-40 text-right text-neutral-500 text-sm">  {/* Reduced font size */}
      {label}
    </div>
  </div>
);

const AdDetails = ({ details }) => (
  <section className="flex flex-col px-5 pt-3.5 mt-2 max-w-full text-base font-medium leading-tight w-[600px]">  {/* Increased width */}
    <h2 className="text-neutral-600 max-md:max-w-full text-sm">Ad Details</h2>  {/* Reduced font size */}
    <div className="flex flex-col mt-5 w-full max-md:max-w-full">
      {details.map((item, index) => (
        <AdDetailsItem key={index} {...item} />
      ))}
    </div>
  </section>
);

const AdHeader = ({ page, adStartedOn }) => (
  <header className="flex flex-wrap gap-3.5 items-center px-5 pt-5 pb-3 w-full max-md:max-w-full">
    <img loading="lazy" src={page.display_picture} alt="Profile" className="object-contain shrink-0 self-stretch my-auto w-10 rounded-full aspect-square" />
    <div className="flex flex-col flex-1 shrink justify-center self-stretch my-auto basis-0 min-h-[34px] min-w-[240px] max-md:max-w-full">
      <h1 className="text-lg font-semibold leading-loose text-zinc-800 max-md:max-w-full">
        {page.title}
      </h1>
      <time className="mt-2 text-sm leading-none text-neutral-500 max-md:max-w-full">
        {adStartedOn}
      </time>
    </div>
  </header>
);

const AdDescription = ({ caption }) => (
  <p className="flex-1 shrink gap-2.5 self-stretch px-5 pb-4 mt-2 w-full text-base font-medium leading-6 text-neutral-500 max-md:max-w-full">
    {caption}
  </p>
);

const AdComponent = ({ modalData }) => {
  const details = [
    { icon: '', title: `${modalData.ad_started_on} - Still running`, label: 'Status' },
    { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/9903e3d576156a82c7deb6fede476c3c4b3784813e00729ff98a219595ef971a?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74', title: `${modalData.time_running}`, label: 'Time Running' },
    { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/a69b19de802853a4c9c4c72ed5d8ac9f3fb2beb6d3108d4e6e1884e9362cd313?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74', title: `${modalData.format}`, label: 'Format' },
    { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/c34de27a1160688e7f8169dddc90d0ba49f850e0c78c997da8b6b02147942c3a?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74', title: `${modalData.category?.title}`, label: 'Category' },
    { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/fb045dc1216cdf96d212d7f28e2e3509aa43d02ca0ef1924b735382a813b14a0?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74', title: `${modalData.platform}`, label: 'Platforms' },
    { icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/af239145052e6cc65e8845c8ddaebf48a06bedd7a7ce4330735a6983173cc9df?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74', title: <a href={modalData.page?.link} className="text-blue-500 underline">{modalData.page?.link}</a>, label: 'Landing Page' }  // Ensure link is properly aligned and styled
  ];

  return (
    <article className="flex flex-col grow pb-28 w-full bg-white rounded-none max-md:pb-24 max-md:max-w-full">
      <div className="flex flex-col w-full max-md:max-w-full">
        <AdHeader page={modalData.page} adStartedOn={modalData.ad_started_on} />
        <AdDescription caption={modalData.caption} />
        <hr className="mt-2 max-w-full border border-solid border-zinc-200 min-h-[1px] w-[600px]" />  {/* Increased width */}
        <AdDetails details={details} />
      </div>
    </article>
  );
};

const Modal = ({ isOpen, onClose, modalData }) => {
  if (!isOpen || !modalData) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start overflow-y-auto z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 relative max-w-[90%] w-full mt-10">  {/* Increased width */}
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-600 hover:text-gray-900">
          ✕
        </button>
        <div className="flex gap-5 max-md:flex-col">
          <section className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">  {/* Adjusted width */}
            <img loading="lazy" src={modalData.ad_image_urls[0]} alt="Ad visual" className="object-contain grow w-full rounded-none aspect-[1.22] max-md:max-w-full" />
          </section>
          <aside className="flex flex-col ml-5 w-[40%] max-md:ml-0 max-md:w-full overflow-y-auto max-h-[70vh]">  {/* Adjusted width */}
            <AdComponent modalData={modalData} />
          </aside>
        </div>
      </div>
    </div>
  );
}

export default Modal;
