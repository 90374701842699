import React from "react";

function Clients() {
  return (
    <section className="flex flex-wrap gap-5 justify-center items-center self-center mt-14 w-full max-w-[987px] max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center self-stretch max-md:max-w-full">
        <div className="text-6xl font-bold leading-tight text-center text-zinc-800 max-md:max-w-full max-md:text-4xl">
          <p>Trusted By Many</p>
        </div>
        <div className="self-start mt-3 text-sm leading-5 text-center text-zinc-400 max-md:max-w-full">
          Take a look at what some of our prominent and genuine clients say about how we've helped businesses like yours succeed online.
        </div>
      </div>
      <img 
        loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/bc6575ea-5d98-4753-82a5-dd6d5a69c1b1?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" 
        alt="Client Logo" 
        className="object-contain shrink-0 self-stretch my-auto bg-white rounded-full aspect-square h-[78px] w-[78px]" 
      />
    </section>
  );
}

export default Clients;
