import React from "react";

function Footer() {
  return (
    <footer className="px-16 py-20 mx-auto my-10 w-full max-w-[1216px] border border-solid bg-blend-normal border-slate-200 rounded-[40px] max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[41%] max-md:w-full">
          <div className="flex flex-col items-start w-full text-base text-zinc-400 max-md:mt-10">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/49cc3f5c-c4fd-493d-a620-48647f200366?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Company Logo" className="object-contain aspect-[1.68] w-[99px]" />
            <div className="self-stretch mt-7 leading-6">
              Ready to elevate your QA process? Contact us today to discuss your project and discover how we can bring your application’s vision to life.
            </div>
            <div className="flex gap-3 mt-9">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/243102ce39c8d86d2ae3599cb7627c64dec24760469ad5a5521bdf9f45bebf3d?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Heart icon" className="object-contain shrink-0 w-6 aspect-square" />
              <div className="basis-auto">Made with love in Nepal</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[59%] max-md:w-full">
          <div className="flex flex-wrap gap-10 mt-7 max-md:mt-10 max-md:max-w-full">
            <div className="self-start text-base font-bold text-zinc-800">Home</div>
            <div className="flex flex-col grow shrink-0 basis-0 w-fit">
              <div className="flex gap-5 justify-between text-base whitespace-nowrap text-zinc-400 max-md:mr-1.5">
                <div>About</div>
                <div>Resources</div>
                <div>Services</div>
                <div>Blogs</div>
              </div>
              <div className="flex gap-3.5 self-end mt-32 max-md:mt-10">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/925a140217fd691dc9ef3b31e83f541edad22fe6c31a13aa057df71d9553f03e?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Social icon 1" className="object-contain shrink-0 aspect-square w-[66px]" />
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f9ab93b883e726c65af641c0e85803691c3de470b6961089950f4c1d495cf34c?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Social icon 2" className="object-contain shrink-0 aspect-square w-[66px]" />
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f3123ad832c7017f8bbc9f4a76ad69c37f84bbe8ed4050d0bbc400d688d99d34?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Social icon 3" className="object-contain shrink-0 aspect-square w-[66px]" />
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/3f2f68b57de7c5fd940e6a062639129e6d3fd9f9140ee60e2983a70f44ba82eb?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Social icon 4" className="object-contain shrink-0 aspect-square w-[66px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
