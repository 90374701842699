// 
import React, { useState } from 'react';

function CategoryItem({ name, onCategoryChange }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    const updatedChecked = !isChecked;
    setIsChecked(updatedChecked);
    onCategoryChange(name, updatedChecked); // Pass the category name and its checked status to parent
  };

  return (
    <div className="flex items-center justify-between px-3.5 py-2.5 w-full bg-white min-h-[40px]">
      <span className="text-sm leading-none text-zinc-800">{name}</span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className="w-5 h-5 rounded-md border border-solid border-stone-300"
      />
    </div>
  );
}

export default CategoryItem;
