import React, { useState } from "react";

const DatePicker = ({ label, value, onChange }) => (
  <div className="flex flex-col flex-1 shrink bg-white basis-0">
    <label
      htmlFor={`${label.toLowerCase()}Date`}
      className="text-sm leading-none font-[450] text-zinc-800"
    >
      {label}
    </label>
    <div className="flex flex-col mt-1 w-full text-base leading-none rounded-xl font-[420] text-zinc-800">
      <div className="flex overflow-hidden gap-2.5 items-center px-3.5 py-3 w-full bg-white rounded-xl border border-solid border-zinc-200">
        <input
          type="date"
          id={`${label.toLowerCase()}Date`}
          className="flex-1 shrink self-stretch my-auto basis-0"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  </div>
);

const ImageUploader = ({ onImageUpload }) => {
  const [imagePreview, setImagePreview] = useState(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      onImageUpload(file);
    }
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      onImageUpload(file);
    }
  };

  return (
    <section className="flex flex-col pt-3.5 pl-4 text-sm leading-5 text-center min-h-[386px] min-w-[240px] text-neutral-500 w-[310px]">
      <div
        className="flex flex-col flex-1 justify-center py-8 w-full bg-gray-50 rounded-lg border border-dashed border-stone-300 max-sm:-ml-1"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {imagePreview ? (
          <img
            src={imagePreview}
            alt="Preview"
            className="object-contain self-center w-full h-full rounded-lg"
          />
        ) : (
          <>
            <p className="mt-4">
              Drag and drop your image here or <br />
              <label
                htmlFor="file-upload"
                className="cursor-pointer text-blue-600"
              >
                click here to browse a file
              </label>
            </p>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleChange}
              accept="image/*"
            />
          </>
        )}
      </div>
    </section>
  );
};

const KeywordInput = ({ value, onChange }) => (
  <div className="flex flex-col mt-5 w-full bg-white">
    <label
      htmlFor="keywords"
      className="text-sm leading-none font-[450] text-zinc-800"
    >
      Keywords
    </label>
    <div className="flex flex-col mt-1 w-full text-base leading-none rounded-xl font-[420] text-zinc-800">
      <input
        id="keywords"
        className="overflow-hidden flex-1 shrink gap-2.5 self-stretch px-3.5 py-3 w-full bg-white rounded-xl border border-solid border-zinc-200"
        value={value}
        onChange={onChange}
        placeholder="Eg: Fast Food, Burger, Sandwich"
      />
    </div>
  </div>
);

const CaptionInput = ({ value, onChange }) => (
  <div className="flex flex-col mt-5 w-full text-base leading-5 rounded-xl font-[420] text-zinc-800">
    <textarea
      className="overflow-hidden gap-2.5 self-stretch px-3.5 py-3 w-full bg-white rounded-xl border border-solid border-zinc-200"
      placeholder="Write a caption..."
      rows={5}
      value={value}
      onChange={onChange}
    ></textarea>
  </div>
);

const Button = ({
  text,
  icon,
  variant = "primary",
  className = "",
  onClick,
  disabled = false,
}) => {
  const baseClasses = "gap-1.5 self-stretch px-3.5 py-2.5 my-auto";
  const variantClasses = {
    primary: `font-medium text-white bg-blue-600 rounded-xl ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`,
    secondary:
      "whitespace-nowrap bg-white rounded-xl border border-solid border-zinc-200 font-[420] text-zinc-800",
  };
  return (
    <button
      className={`${baseClasses} ${variantClasses[variant]} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <img
          loading="lazy"
          src={icon}
          className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
          alt=""
        />
      )}
      {text}
    </button>
  );
};

function SchedulePost({ onClose }) {
  const [fromDate, setFromDate] = useState("");
  const [tillDate, setTillDate] = useState("");
  const [keywords, setKeywords] = useState("");
  const [caption, setCaption] = useState("");
  const [imageFile, setImageFile] = useState(null); // State to store the uploaded image file
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state

  const handleImageUpload = (file) => {
    setImageFile(file);
    console.log("Image uploaded:", file);
  };

  const handleCaptionWithAI = async () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      alert("Access token is missing.");
      return;
    }

    if (!keywords) {
      alert("Please enter a keyword");
      return;
    }

    const keywordArray = keywords.split(" ").map((keyword) => keyword.trim());

    setIsLoading(true);

    try {
      const response = await fetch(
        "http://127.0.0.1:8000/api/v1/auto-generate-caption",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            method: "auto",
            keywords: keywordArray,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCaption(data.generated_caption); // Populate the caption with the generated text
      } else {
        console.error("Failed to generate caption:", response.statusText);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const handleSchedulePost = () => {
    if (!imageFile || !caption) {
      alert("Please insert Image and Caption");
      return;
    }
    alert("The Ad has been scheduled, Facebook will now initiate the approval process");
    console.log("Scheduling post");
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <article className="flex flex-col rounded-xl max-w-[700px] bg-white">
        <header className="flex overflow-hidden flex-col w-full bg-white rounded-xl max-md:max-w-full">
          <div className="flex flex-wrap gap-2.5 items-center p-4 w-full bg-white border-b border-zinc-200 max-md:max-w-full">
            <h1 className="flex-1 shrink self-stretch my-auto text-base font-medium basis-0 text-zinc-900 max-md:max-w-full">
              Schedule Post
            </h1>
            <button
              onClick={onClose}
              className="flex gap-2.5 items-center self-stretch p-1 my-auto w-6 h-6 rounded-3xl bg-zinc-100"
            >
              <img
                loading="lazy"
                src="Close.png"
                className="object-contain self-stretch my-auto w-4 aspect-square"
                alt=""
              />
            </button>
          </div>
        </header>
        <main className="flex flex-wrap items-start pb-5 w-full max-md:max-w-full">
          <ImageUploader onImageUpload={handleImageUpload} />
          <section className="flex flex-col flex-1 shrink items-center px-4 pt-3.5 bg-white aspect-square basis-0 min-w-[240px]">
            <div className="flex flex-col w-full">
              <div className="flex gap-4 items-start w-full">
                <DatePicker
                  label="From"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <DatePicker
                  label="Till"
                  value={tillDate}
                  onChange={(e) => setTillDate(e.target.value)}
                />
              </div>
              <KeywordInput
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
              <CaptionInput
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
              <Button
                text={
                  isLoading ? (
                    <span>Loading...</span>
                  ) : (
                    <span className="text-white">Caption with AI</span>
                  )
                }
                icon="Caption.png"
                className="flex gap-1.5 justify-center items-center px-2 py-2.5 mt-3 w-full text-base leading-none bg-blue-400 rounded-xl font-[420]"
                onClick={handleCaptionWithAI}
                disabled={isLoading} // Disable button while loading
              />
            </div>
          </section>
        </main>
        <footer className="flex flex-wrap gap-2.5 items-end justify-end p-4 w-full text-base leading-none bg-white border-t border-zinc-200 max-md:max-w-full">
          <Button
            text="Cancel"
            variant="secondary"i
            onClick={onClose}
            className="ml-auto"
          />
          <Button
            text="Schedule Post"
            variant="primary"
            onClick={handleSchedulePost}
          />
        </footer>
      </article>
    </div>
  );
}

export default SchedulePost;
