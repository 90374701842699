import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  return (
    <div className="flex items-center justify-between px-60 py-5 text-base max-md:px-5 w-full">
      <div className="flex items-center gap-5 min-w-[280px] text-neutral-600 max-md:max-w-full">
        <img
          loading="lazy"
          src="logo1.ico"
          className="object-contain w-10 aspect-square"
          alt="Logo"
        />
        <div className="flex gap-8 text-base text-neutral-600">
          <div className="self-center">Features</div>
          <div className="self-center">Pricing</div>
        </div>
      </div>
      <div className="flex gap-4 items-center py-3 pr-1.5 pl-3 bg-gray-50 rounded-xl border border-solid border-zinc-200 font-[300] min-w-[426px] text-neutral-400 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee52a30e847f9586edd66ddf2ee2911580e6b4760ecd24d94e8866b23db05abd?apiKey=1861f19fd7354dc091ef5609ab16de74&&apiKey=1861f19fd7354dc091ef5609ab16de74"
          className="object-contain w-6 aspect-square"
          alt="Search icon"
        />
        <div className="flex-1">
          Search by company or category
        </div>
      </div>
      <div className="flex gap-3 items-center">
        <div
          onClick={handleLoginClick}
          className="cursor-pointer px-4 py-3 bg-white rounded-xl border border-solid border-zinc-200 font-[300] text-zinc-800 whitespace-nowrap"
        >
          Login
        </div>
        <div
          onClick={handleSignupClick}
          className="cursor-pointer px-4 py-3 font-semibold text-white bg-sky-600 rounded-xl whitespace-nowrap"
        >
          Start Free Trial
        </div>
      </div>
    </div>
  );
};

export default Header;
