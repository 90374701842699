import React from 'react';

const SideBar = ({ activeTab, setActiveTab }) => {
  const sidebarItems = [
    { 
      icon: "discover.png",
      text: "Discover Ads",
      tabKey: 'discover-ads'
    },
    { 
      icon: "save.png",
      text: "Saved Ads",
      tabKey: 'saved-ads'
    },
  ];

  return (
    <aside className="sticky top-0 flex flex-col justify-between shrink-0 bg-gray-50 basis-1/6 grow-0 w-72 min-h-screen pt-4">
      <div className="flex flex-col flex-grow">
        <div className="flex gap-2.5 items-center mx-5 text-l text-zinc-800 max-md:mx-3.5">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6dc69d58487fa452594bcc36c238088c7be70fb0a66682fd03e04b70982aaba?apiKey=1861f19fd7354dc091ef5609ab16de74" 
            alt="" 
            className="object-contain shrink-0 self-stretch my-auto w-9 rounded-none aspect-square" 
          />
          <div className="flex-1 shrink self-stretch my-auto basis-0">
            <span className="">Adstore.tech</span>
          </div>
        </div>
        <nav className="flex flex-col px-3 pt-3 pb-3.5 mt-3 text-sm leading-none text-zinc-800">
          {sidebarItems.map((item, index) => (
            <button
              key={index}
              onClick={() => setActiveTab(item.tabKey)}
              className={`flex gap-2.5 items-center px-3 py-2 rounded-lg ${activeTab === item.tabKey ? 'bg-gray-200 text-black' : 'text-zinc-800'}`}
            >
              <img 
                loading="lazy" 
                src={item.icon} 
                alt="" 
                className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" 
              />
              <span className="flex-1">{item.text}</span>
            </button>
          ))}
        </nav>
      </div>
      <div className="flex gap-2.5 items-center mx-3 min-h-[40px] mt-auto pb-5 w-full max-md:mx-2.5">
        <img 
          loading="lazy" 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/62d41e0fe47eb99fcdf82f9ecd55036f79e3f9652d7128062949a4140c16fce2?apiKey=1861f19fd7354dc091ef5609ab16de74&&apiKey=1861f19fd7354dc091ef5609ab16de74" 
          alt="User avatar" 
          className="object-contain shrink-0 self-stretch my-auto w-10 aspect-square rounded-[40px]" 
        />
        <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-h-[38px]">
          <div className="flex-1 text-sm font-medium leading-none text-zinc-800">Ritesh Thapa</div>
          <div className="text-sm leading-none text-neutral-600">mr.ritesh.thapa@gmail.com</div>
        </div>
      </div>
    </aside>
  );
}

export default SideBar;
