import React, { useState } from 'react';

const categories = [
  { name: 'VOIP' },
  { name: 'E-commerce' },
  { name: 'Food and Beverages' },
  { name: 'Jobs & Education' },
  { name: 'Autos and Vehicles' },
  { name: 'Beauty and Fitness' },
  { name: 'Health' },
];

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className="flex overflow-hidden gap-2.5 items-center self-stretch px-3.5 py-2.5 my-auto bg-white rounded-xl border border-solid border-zinc-200 min-h-[40px] min-w-[240px] text-neutral-400 w-[350px]">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ce295bcdc3f4a2275fb1392cf4c1623e831ee8d789295446fb9d6076c2b3f6d?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74"
        alt=""
        className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
      />
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by category or company..."
        className="self-stretch my-auto w-[292px] bg-transparent border-none outline-none"
        aria-label="Search by category or company"
      />
    </div>
  );
};

const TabButton = ({ label, isActive, onClick }) => {
  return (
    <button
      className={`gap-1 self-stretch px-2.5 py-3 rounded-lg min-h-[32px] ${
        isActive
          ? 'bg-white border border-solid border-zinc-200 shadow-[0px_4px_4px_rgba(0,0,0,0.05)] text-zinc-800'
          : 'text-neutral-500'
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

const CategoryItem = ({ name, isSelected, onSelect }) => {
  return (
    <div 
      className="flex gap-2.5 items-center px-3.5 py-2.5 w-full bg-white min-h-[40px] cursor-pointer hover:bg-gray-100"
      onClick={onSelect}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onChange={onSelect}
        className="form-checkbox h-5 w-5 text-blue-600"
      />
      <div className="flex-1 shrink self-stretch my-auto text-sm leading-none basis-0 text-zinc-800">
        {name}
      </div>
    </div>
  );
};

const CategoryDropDown = ({ selectedCategories, onCategoryChange }) => {
  const toggleCategory = (categoryName) => {
    const updatedCategories = selectedCategories.includes(categoryName)
      ? selectedCategories.filter(name => name !== categoryName)
      : [...selectedCategories, categoryName];
    onCategoryChange(updatedCategories);
  };

  return (
    <section className="absolute z-10 flex flex-col py-1.5 mt-1 bg-white rounded-xl border border-solid shadow-sm border-zinc-200 max-w-[250px] right-0">
      {categories.map((category, index) => (
        <CategoryItem
          key={index}
          name={category.name}
          isSelected={selectedCategories.includes(category.name)}
          onSelect={() => toggleCategory(category.name)}
        />
      ))}
    </section>
  );
};

const FilterButton = ({ onFilter, toggleDropdown }) => {
  return (
    <button
      onClick={toggleDropdown}
      className="flex gap-1.5 justify-center items-center self-stretch px-3.5 py-2.5 my-auto bg-white rounded-xl border border-solid border-zinc-200"
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b3589865d8cd37d7ad7bbf4fe068bc427bb2053383255f24ba46644c7189abf7?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74"
        alt=""
        className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
      />
      <span className="self-stretch my-auto">Select Categories</span>
    </button>
  );
};

const SearchButtonFilter = ({ onSearch, onToggle, onFilter }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('Meta Ads');
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const tabs = [
    { label: 'Meta Ads', isActive: activeTab === 'Meta Ads' },
    { label: 'LinkedIn Ads', isActive: activeTab === 'LinkedIn Ads' },
  ];

  const handleSearchChange = (term) => {
    setSearchTerm(term);
    onSearch(term); // Trigger search function
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    onToggle(tab); // Trigger toggle function
  };

  const toggleDropdown = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  const handleCategoryChange = (categories) => {
    setSelectedCategories(categories);
    onFilter(categories);
  };

  return (
    <header className="relative flex flex-wrap gap-3 items-center px-8 pt-4 pb-6 text-base leading-none font-[420] max-md:px-5">
      <SearchBar searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
      <nav className="flex gap-1 items-start self-stretch p-1 my-auto text-sm leading-none text-right rounded-xl bg-zinc-100 font-[450]">
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            label={tab.label}
            isActive={tab.isActive}
            onClick={() => handleTabClick(tab.label)}
          />
        ))}
      </nav>
      <div className="relative ml-auto"> {/* This div pushes the dropdown to the right */}
        <FilterButton onFilter={onFilter} toggleDropdown={toggleDropdown} />
        {isCategoryDropdownOpen && (
          <CategoryDropDown
            selectedCategories={selectedCategories}
            onCategoryChange={handleCategoryChange}
          />
        )}
      </div>
    </header>
  );
};

export default SearchButtonFilter;
