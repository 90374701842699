import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://adstore.tech/">
        Adstore Tech
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

function SignUpWithGoogleButton() {
  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <Box sx={{ width: '100%' }}>
        <GoogleLogin
          onSuccess={(response) => {
            console.log('Login Success:', response);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          render={({ onClick, disabled }) => (
            <Button
              onClick={onClick}
              disabled={disabled}
              fullWidth
              variant="outlined"
              sx={{
                mt: 10,
                mb: 2,
                borderColor: '#dadce0',
                color: '#3c4043',
                textTransform: 'none',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60px',
                '& .MuiButton-startIcon': {
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '8px',
                },
                '& img': {
                  width: '20px',
                  height: '20px',
                },
              }}
              startIcon={<img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />}
            >
              Sign up with Google
            </Button>
          )}
        />
      </Box>
    </GoogleOAuthProvider>
  );
}

export default function SignUp() {
  const [emailError, setEmailError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordStrength, setPasswordStrength] = React.useState(0);
  const navigate = useNavigate();

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);

    let strength = 0;
    if (value.length >= 8) strength += 25;
    if (/[A-Z]/.test(value)) strength += 25;
    if (/[0-9]/.test(value)) strength += 25;
    if (/[^A-Za-z0-9]/.test(value)) strength += 25;

    setPasswordStrength(strength);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);

      const payload = {
        profile: {
          first_name: firstName,
          last_name: lastName,
        },
        email: email,
        password: password,
        confirm_password: password,
      };

      try {
        const response = await axios.post('http://127.0.0.1:8000/api/v1/accounts/signup/', payload);
        console.log('Signup successful:', response.data);
        navigate('/Login');
      } catch (error) {
        console.error('Signup error:', error);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              height: 42,
              width: 42,
              mb: 4,
              mt: 10,
            }}
            alt="Adstore Logo"
            src={`${process.env.PUBLIC_URL}/Logo1.ico`}
          />
          <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
            Create your Adstore account
          </Typography>
          <SignUpWithGoogleButton />
          <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
            <Divider>
              <Typography variant="body2" color="textSecondary">
                or sign up with email
              </Typography>
            </Divider>
          </Box>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  size="small"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  InputLabelProps={{
                    style: { fontSize: '0.85rem' },
                  }}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  InputLabelProps={{
                    style: { fontSize: '0.85rem' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  size="small"
                  label="Email Address"
                  name="email"
                  InputLabelProps={{
                    style: { fontSize: '0.85rem' },
                  }}
                  autoComplete="email"
                  error={emailError}
                  helperText={emailError ? "Invalid email address" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  size="small"
                  label="Set up a strong password"
                  InputLabelProps={{
                    style: { fontSize: '0.85rem' },
                  }}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handlePasswordChange}
                />
                <Box sx={{ width: '100%', mt: 1 }}>
                  <LinearProgress variant="determinate" value={passwordStrength} />
                  <Typography variant="body2" color="textSecondary">
                    Password strength: {passwordStrength}%
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" size="small" />}
                  label={
                    <Typography variant="body2" style={{ fontSize: '0.85rem' }}>
                      I agree with Adstore’s Terms of Service, Privacy Policy and Cookie Policy.
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/Login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
