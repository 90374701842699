import React from "react";

function SectionTitle({ title, subtitle }) {
  return (
    <section className="self-start mt-40 text-6xl font-bold leading-tight text-zinc-800 max-md:mt-10 max-md:text-4xl">
      {title}
      {subtitle && <div className="mt-4 mr-8 text-base leading-6 text-zinc-400 max-md:mr-2.5">{subtitle}</div>}
    </section>
  );
}

export default SectionTitle;