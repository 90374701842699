import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    // Clear error if email is valid
    setError("");

    // Here you can add your logic to send the email
    setSubmitted(true);
    setTimeout(() => {
      setSubmitted(false);
    }, 3000); // 3 seconds
  };

  return (
    <Container
      maxWidth="xs"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <LockIcon style={{ fontSize: 50, marginBottom: "20px",marginTop:"100px", color: "#fbbc05" }} />
      <Typography component="h1" variant="h5">
        Forgot Password
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "10px" }}>
        Enter your email below to receive a password reset link
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%", marginTop: "10px" }}>
        <TextField
          variant="outlined"
          margin="normal"
          size="small"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
          helperText={error}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" style={{ margin: "15px 0",lineHeight:"30px" }}>
          Reset Password
        </Button>
      </form>
      {submitted && (
        <Alert severity="success" style={{ marginBottom: "10px" }}>
          An email has been sent to {email}. Please check your inbox and verify.
        </Alert>
      )}
      <Button component={Link} to="/login" fullWidth variant="outlined" color="secondary" style={{ margin: "10px 0",lineHeight:"30px" }} >
        Back to sign in
      </Button>
      <Box mt={2} display="flex" alignItems="center">
              <Typography variant="body2" style={{ marginRight: "8px" }}>
                Having trouble logging in?
              </Typography>
              <Link to="/support" style={{ textDecoration: "underline", color: "blue" }}>
                <Typography variant="body2" style={{ color: "inherit" }}>
                  Contact Support
                </Typography>
              </Link>
            </Box>
    </Container>
  );
}

export default ForgotPassword;
