import React from 'react';

const AdModal = ({ modalData, isOpen, onClose }) => {
  if (!isOpen || !modalData) return null;

  const details = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9903e3d576156a82c7deb6fede476c3c4b3784813e00729ff98a219595ef971a?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
      title: modalData.ad_started_on || "Apr 7, 2024 - Apr 8, 2024",
      subtitle: "Time Running",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a69b19de802853a4c9c4c72ed5d8ac9f3fb2beb6d3108d4e6e1884e9362cd313?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
      title: "Image", 
      subtitle: "Format",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c34de27a1160688e7f8169dddc90d0ba49f850e0c78c997da8b6b02147942c3a?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
      title: modalData.category?.title || "Entertainment", 
      subtitle: "Category",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fb045dc1216cdf96d212d7f28e2e3509aa43d02ca0ef1924b735382a813b14a0?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
      title: modalData.platform || "Facebook, Instagram, Messenger", 
      subtitle: "Platforms",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/af239145052e6cc65e8845c8ddaebf48a06bedd7a7ce4330735a6983173cc9df?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74",
      title: modalData.page?.link || "wholesale.southerngrace.com", 
      subtitle: "Landing Page",
    },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
      <div className="bg-white rounded-lg shadow-lg p-8 relative max-w-5xl w-full max-h-full overflow-y-auto">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 z-10"
        >
          ✕
        </button>
        <div className="flex gap-6 max-md:flex-col">
          <div className="flex flex-col w-[55%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={modalData.ad_image_urls[0]}
              className="object-contain grow w-full rounded-lg aspect-[1.22] max-md:max-w-full"
              alt="Ad Image"
            />
          </div>
          <div className="flex flex-col ml-6 w-[45%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow pb-28 w-full bg-white rounded-lg max-md:pb-24 max-md:max-w-full">
              <div className="flex flex-col w-full max-md:max-w-full">
                <AdHeader page={modalData.page} adStartedOn={modalData.ad_started_on} />
                <AdContent caption={modalData.caption} />
                <AdDetails details={details} status={modalData.status} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdHeader = ({ page, adStartedOn }) => {
  return (
    <div className="flex flex-wrap gap-3.5 items-center px-5 pt-5 pb-3 w-full max-md:max-w-full">
      <img
        loading="lazy"
        src={page.display_picture}
        className="object-contain shrink-0 self-stretch my-auto w-12 rounded-full aspect-square"
        alt="Profile"
      />
      <div className="flex flex-col flex-1 shrink justify-center self-stretch my-auto basis-0 min-h-[34px] min-w-[240px] max-md:max-w-full">
        <div className="text-lg font-semibold leading-loose text-zinc-800 max-md:max-w-full">
          {page.title}
        </div>
        <div className="mt-2 text-sm leading-none text-neutral-500 max-md:max-w-full">
          {adStartedOn}
        </div>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1224c658c8b2659d4721e51a9ea8bd70ec35c18da71a8bed5c4a3405b5e57389?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74"
        className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
        alt="Logo"
      />
    </div>
  );
};

const AdContent = ({ caption }) => {
  return (
    <>
      <div className="flex-1 shrink gap-2.5 self-stretch px-5 pb-4 mt-2 w-full text-base font-medium leading-6 text-neutral-500 max-md:max-w-full">
        {caption}
      </div>
      <div className="mt-2 max-w-full border border-solid border-zinc-200 min-h-[1px] w-full" />
    </>
  );
};

const AdDetails = ({ details, status }) => {
  return (
    <div className="flex flex-col px-5 pt-3.5 mt-2 max-w-full text-base font-medium leading-tight">
      <div className="text-neutral-600 max-md:max-w-full mb-4">Ad Details</div>
      <div className="flex flex-col mt-5 w-full max-md:max-w-full">
        <div className="flex items-center justify-between py-2 w-full">
          <div className="flex items-center gap-4">
            <div className="flex shrink-0 self-stretch my-auto w-6 h-6 bg-green-600 rounded-full border-emerald-100 border-solid border-[5px]" />
            <div className="text-zinc-800">
              {status || "Still running"}
            </div>
          </div>
          <div className="text-right text-neutral-500">Status</div>
        </div>
        {details.map((detail, index) => (
          <AdDetailItem key={index} {...detail} />
        ))}
      </div>
    </div>
  );
};

const AdDetailItem = ({ icon, title, subtitle }) => {
  return (
    <div className="flex items-center justify-between py-2 mt-2 w-full">
      <div className="flex items-center gap-4">
        <img
          loading="lazy"
          src={icon}
          className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
          alt={subtitle}
        />
        <div className="text-zinc-800 break-all">{title}</div>
      </div>
      <div className="text-right text-neutral-500">{subtitle}</div>
    </div>
  );
};

export default AdModal;
