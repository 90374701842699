import React, { useState } from 'react';
import axios from 'axios';
import Modal from './Modal';

const AdGallery = ({ ads }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleSave = async (adId) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      alert("User is not authenticated");
      return;
    }

    try {
      await axios.post(
        `http://127.0.0.1:8000/api/v1/ads/save/${adId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      alert("Ad saved successfully!");
    } catch (error) {
      console.error("Error saving ad:", error);
      alert("Failed to save the ad.");
    }
  };

  const handlePreview = async (adId) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      alert("User is not authenticated");
      return;
    }

    try {
      const response = await axios.get(
        `http://127.0.0.1:8000/api/v1/discover-ads/${adId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setModalData(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching ad details:", error);
      alert("Failed to load the ad details.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  return (
    <div className="flex-1 overflow-y-auto p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {ads.map((ad, index) => (
          <div key={index} className="flex flex-col w-full">
            <div className="flex overflow-hidden flex-col grow w-full bg-white rounded-lg border border-solid border-zinc-200 mb-5">
              <div className="flex flex-col w-full">
                <div className="flex gap-3.5 items-center px-4 pt-4 pb-3 w-full">
                  <img loading="lazy" src={ad.profileImage} alt={`${ad.name} profile`} className="object-contain shrink-0 self-stretch my-auto rounded-full aspect-square w-[34px]" />
                  <div className="flex flex-col flex-1 shrink justify-center self-stretch my-auto basis-0 min-h-[34px] min-w-[240px]">
                    <div className="text-base font-semibold leading-loose text-zinc-800">{ad.name}</div>
                    <div className="mt-2 text-xs leading-none text-neutral-500">{ad.date}</div>
                  </div>
                </div>
                <CaptionWithToggle content={ad.content} />
              </div>
              <div className="flex flex-col mt-4 w-full">
                <img loading="lazy" src={ad.adImage} alt="Ad" className="object-contain w-full aspect-[1.71]" />
                <div className="flex gap-2.5 items-center px-4 py-3.5 w-full bg-white">
                  <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-6">
                    <div className="text-xs leading-none text-neutral-500">{ad.platform}</div>
                    <div className="mt-2 text-sm font-medium leading-none text-zinc-800">{ad.name}</div>
                  </div>
                  <button
                    onClick={() => handlePreview(ad.id)}
                    className="gap-1.5 self-stretch px-3 py-2 my-auto text-base leading-none whitespace-nowrap bg-white rounded-xl border border-solid border-zinc-200 font-[420] text-zinc-800 cursor-pointer"
                  >
                    Preview
                  </button>
                  <button
                    onClick={() => handleSave(ad.id)}
                    className="flex gap-2.5 items-center self-stretch p-2 my-auto w-9 h-9 bg-white rounded-xl border border-solid border-zinc-200 cursor-pointer"
                  >
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/af65b581eaeff823497dc0b914c874dfc2dcfc760a0320c5f50382589f280f13?placeholderIfAbsent=true&apiKey=1861f19fd7354dc091ef5609ab16de74" alt="Save Ads" className="object-contain w-5 aspect-square" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} modalData={modalData} />
      )}
    </div>
  );
};

const CaptionWithToggle = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const limit = 200;

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return `${text.slice(0, limit)}...`;
    }
    return text;
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex-1 shrink gap-2.5 self-stretch px-4 mt-2 w-full text-sm font-medium leading-5 text-neutral-500">
      {isExpanded ? content : truncateText(content, limit)}
      {content.length > limit && (
        <span
          className="text-zinc-800 cursor-pointer ml-1"
          onClick={toggleReadMore}
        >
          {isExpanded ? " Show less" : "... See more"}
        </span>
      )}
    </div>
  );
};

export default AdGallery;
