import React, { useState, useContext } from 'react';
import SideBar from './SideBar';
import MainContent from './MainContent'; 
import Header from './Header';
import { AuthContext } from '../context/AuthContext';

const Layout = () => {
  const { logout } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('discover-ads'); // Default tab

  const handleLogout = () => {
    logout();
    window.location.href = '/login'; // Redirect to login page after logout
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <SideBar activeTab={activeTab} setActiveTab={setActiveTab} /> {/* Sidebar takes a fixed width */}
      <div className="flex flex-col flex-1 overflow-y-auto"> {/* Main content area (Header + MainContent) */}
        <Header />
        <MainContent activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </div>
  );
}

export default Layout;
