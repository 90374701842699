import React, { useState } from 'react';
import axios from 'axios';
import Modal from './Modal'; // Ensure Modal is imported

const AdCard = ({ id, company, date, content, imageSrc, platform }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const getTruncatedContent = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const MAX_CHARS = 70;
  const truncatedContent = getTruncatedContent(content, MAX_CHARS);

  const handlePreviewClick = async () => {
    try {
      const response = await axios.get(`http://127.0.0.1:8000/api/v1/ads/${id}`);
      setModalData(response.data); // Set the modal data
      setIsModalOpen(true); // Open the modal
    } catch (error) {
      console.error('Failed to fetch ad details:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  return (
    <article className="flex flex-col grow rounded-lg border border-solid border-zinc-200 max-md:mt-5">
      <header className="flex gap-3.5 px-4 pt-3.5 pb-3 capitalize">
        <img
          loading="lazy"
          src={company.logo}
          alt={`${company.name} logo`}
          className="shrink-0 self-start rounded-full aspect-square w-[34px]"
        />
        <div className="flex flex-col flex-1 justify-center py-1">
          <h3 className="text-base font-semibold leading-8 text-zinc-800">
            {company.name}
          </h3>
          <time className="mt-2 text-xs leading-3 text-neutral-500">{date}</time>
        </div>
      </header>
      <p className="mx-4 mt-2 text-sm font-medium leading-5 text-zinc-800 max-md:mx-2.5">
        {isExpanded ? content : truncatedContent}
        {content.length > MAX_CHARS && (
          <a
            href="#!"
            onClick={handleClick}
            className="text-blue-500 ml-2"
          >
            {isExpanded ? 'See Less' : 'See More'}
          </a>
        )}
      </p>
      {imageError ? (
        <p className="mt-4 w-full text-center text-red-500">Image failed to load</p>
      ) : (
        <img
          loading="lazy"
          src={imageSrc}
          alt="Ad content"
          className="mt-4 w-full aspect-[1.47]"
          onError={handleImageError}
        />
      )}
      <footer className="flex gap-2.5 px-4 py-3.5 bg-gray-50">
        <div className="flex flex-col flex-1 my-auto">
          <div className="text-xs leading-3 capitalize text-neutral-500">
            {platform}
          </div>
          <div className="mt-2 text-sm font-medium leading-5 text-zinc-800">
            {company.name}
          </div>
        </div>
        <button
          className="flex gap-1.5 justify-center px-3 py-2 text-base leading-5 whitespace-nowrap bg-white rounded-xl border border-solid border-zinc-200 font-[420] text-zinc-800"
          onClick={handlePreviewClick}
        >
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/38f2cca9a4316901c70103450fc8be27ad6b2a0db965fbb37fbc0dbf708efeaf?apiKey=1861f19fd7354dc091ef5609ab16de74&"
            alt=""
            className="shrink-0 w-5 aspect-square"
          />
          <span className="my-auto">Preview</span>
        </button>
      </footer>

      {/* Render the Modal */}
      {isModalOpen && modalData && (
        <Modal isOpen={isModalOpen} onClose={closeModal} modalData={modalData} />
      )}
    </article>
  );
};

export default AdCard;
