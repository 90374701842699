import React, { useState, useEffect } from 'react';
import AdGrid from './AdGrid';
import ToggleCategory from './ToggleCategory';

const MainContent = () => {
  const [ads, setAds] = useState([]);
  const [activeConfig, setActiveConfig] = useState({ platform: 'Meta Ads', categories: [] });

  useEffect(() => {
    const fetchAds = async () => {
      const { platform, categories } = activeConfig;
      const categoryQuery = categories.join(',');

      try {
        const response = await fetch(
          `http://127.0.0.1:8000/api/v1/ads/${platform === 'Meta Ads' ? '' : 'linkedin/'}?categories=${categoryQuery}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const formattedAds = data.map(ad => ({
          id: ad.id,
          company: {
            name: ad.page.title,
            logo: ad.page.display_picture,
          },
          date: ad.ad_started_on,
          content: ad.caption,
          imageSrc: ad.ad_image_urls[0],
          platform: ad.platform,
        }));
        setAds(formattedAds);
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    };

    fetchAds();
  }, [activeConfig]);

  return (
    <div>
      <ToggleCategory setActiveConfig={setActiveConfig} />
      <AdGrid ads={ads} />
    </div>
  );
};

export default MainContent;
